import React from 'react';
import logo from './logo-long-m.png';
import './App.css';
import DesktopContents from "./DesktopContents";

function App() {
    const operatingSystem = getMobileOperatingSystem();
    return (
        <div className="App">
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo"/>
                {getTextBlock()}

                {(operatingSystem === "Android") &&
                    <a className="App-download"
                       href='https://play.google.com/store/apps/details?id=app.ramd.am&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img
                        className="App-download-img"
                        alt='Get it on Google Play'
                        src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/></a>}

                {(operatingSystem === "iOS") &&
                    <a className="App-download App-download-ios"
                       href="https://apps.apple.com/us/app/ramdam-promote-apps-get-paid/id6444769579?itsct=apps_box_badge&amp;itscg=30200"><img
                        className="App-download-ios-img"
                        src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1679616000"
                        alt="Download on the App Store"/></a>
                }
            </header>
        </div>
    );
}

function getTextBlock() {
    switch (getMobileOperatingSystem()) {
        case "Android":
            return <p>Download Ramdam app from Google Play Store to continue</p>;
        case "iOS":
            return <p>Download Ramdam app from App Store to continue</p>;
        default:
            return DesktopContents();
    }
}

function getMobileOperatingSystem() {
    // @ts-ignore
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
        return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    // @ts-ignore
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "iOS";
    }

    return "unknown";
}

export default App;
