import React from 'react';
import './DesktopContents.css';

function DesktopContents() {
    return (
        <div>
            <p className="App-description-contents">Continue on your mobile phone:
                <ol>
                    <li>Open this page on your smartphone.</li>
                    <li>Download the RAMDAM app for free.</li>
                    <div className="App-desktop-buttons">
                        <a className="App-download"
                           href='https://play.google.com/store/apps/details?id=app.ramd.am&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img
                            className="App-download-img App-download-android-img"
                            alt='Get it on Google Play'
                            src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/></a>
                        <a className="App-download App-download-ios"
                           href="https://apps.apple.com/us/app/ramdam-promote-apps-get-paid/id6444769579?itsct=apps_box_badge&amp;itscg=30200"><img
                            className="App-download-img App-download-ios-img"
                            src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1679616000"
                            alt="Download on the App Store"/></a>
                    </div>
                    <li>Follow instructions on the app.</li>
                </ol>
            </p>
        </div>
    );
}

export default DesktopContents;
